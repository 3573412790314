import './MainAbout.css'
import mainAboutImage from '../../../assets/images/mail-about.webp'
import { Link } from 'react-router-dom';
import { ABOUTE_ROUTE } from '../../../utils/paths';
import { useSelector } from 'react-redux';

function MainAbout() {
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
    return (
        <section className="main__about">
            <img src={mainAboutImage} alt="приклад процесу роботи нашої компанії" className="main__about-image" />
            <div className="main__about-texts">
                <h2 className={`main__about-title ${isDarkTheme ? "" : "light"} body-m`}>Про нас</h2>
                <p className={`main__about-description ${isDarkTheme ? "" : "light"} light-text`}>Ми - команда висококваліфікованих фахівців, які з великою пристрастю та професіоналізмом займаються ремонтом електроніки. Наша місія полягає в тому, щоб забезпечити швидкий та якісний сервіс, відновити ваші улюблені гаджети та повернути їх до життя...</p>
                <Link to={ABOUTE_ROUTE} className={`main__about-more ${isDarkTheme ? "" : "light"} body-m`}>Детальніше</Link>
            </div>
        </section>
    )
}

export default MainAbout;