import { useState } from 'react';
import AccordionItem from './AccordionItem/AccordionItem';
import './MainFaq.css'


function MainFaq({ faqItems }) {
    const [openId, setId] = useState(null);

    return (
        <section className="main__faq">
        <ul className="accordion">
      {faqItems.map((item, id) => {
       return <AccordionItem onClick={() => id === openId ? setId(null) : setId(id) } isOpen={id===openId} item={item} key={id} />
      })}
    </ul>
    </section>
    )
}

export default MainFaq