import { useState } from "react";
import { useSelector } from "react-redux";
import "./Prices.css";
import TrianglePrice from "./TrianglePrice/TrianglePrice";

function Prices({
  iphoneArr,
  phoneArr,
  laptopPrice,
  computerArr,
  tvArr,
  macArr,
}) {
  const [isPhoneActive, setIsPhoneActive] = useState(false);
  const [isLaptopActive, setIsLaptopActive] = useState(false);
  const [isComputerActive, setIsComputerActive] = useState(false);
  const [isTvActive, setIsTvActive] = useState(false);
  const isPriceActive =
    isComputerActive || isLaptopActive || isPhoneActive || isTvActive;
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const handlePhoneToggle = () => {
    setIsPhoneActive((prev) => !prev);
    setIsLaptopActive(false);
    setIsComputerActive(false);
    setIsTvActive(false);
  };

  const handleLaptopToggle = () => {
    setIsLaptopActive((prev) => !prev);
    setIsPhoneActive(false);
    setIsComputerActive(false);
    setIsTvActive(false);
  };

  const handleComputerToggle = () => {
    setIsComputerActive((prev) => !prev);
    setIsPhoneActive(false);
    setIsLaptopActive(false);
    setIsTvActive(false);
  };

  const handleTvToggle = () => {
    setIsTvActive((prev) => !prev);
    setIsPhoneActive(false);
    setIsLaptopActive(false);
    setIsComputerActive(false);
  };

  return (
    <div className="prices__page">
      <div className={`prices-wrapper ${isPriceActive ? "closed" : ""}`}>
        <div className="price-wrapper">
          <h2 onClick={handlePhoneToggle} className="price-title body-m">
            Ремонт <span className="prices-special red-color">телефонів та планшетів</span>
            <div
              className={`triangle-price-phone ${
                isPhoneActive ? "active" : ""
              }`}
            >
              <TrianglePrice isActive={isPhoneActive} />
            </div>
          </h2>
          <div
            className={`table-price-wrapper ${isPhoneActive ? "opened" : ""}`}
          >
            <div className="service__prices-prices">
              {phoneArr.map((price, idx) => {
                return (
                  <div
                    key={idx}
                    className={`service__prices-price ${
                      isDarkTheme ? "" : "light"
                    }`}
                  >
                    <p className="service__prices-price-title light-text">
                      {price.title}
                    </p>
                    <p className="service__prices-price-price light-text">
                      {price.price}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="service__prices-texts">
              <p className="service__prices-text body-m">
                Ціни на ремонт iPhone (без урахування вартості запчастин):
              </p>
              <p className="service__prices-text body-m">Вартість</p>
            </div>
            {iphoneArr.map((price, idx) => {
              return (
                <div
                  key={idx}
                  className={`service__prices-price ${
                    isDarkTheme ? "" : "light"
                  }`}
                >
                  <p className="service__prices-price-title light-text">
                    {price.title}
                  </p>
                  <p className="service__prices-price-price light-text">
                    {price.price}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="price-wrapper">
          <h2 onClick={handleLaptopToggle} className="price-title body-m">
            Ремонт <span className="prices-special red-color">ноутбуків</span>
            <div
              className={`triangle-price-phone ${
                isLaptopActive ? "active" : ""
              }`}
            >
              <TrianglePrice isActive={isLaptopActive} />
            </div>
          </h2>
          <div
            className={`table-price-wrapper ${isLaptopActive ? "opened" : ""}`}
          >
            <div className="service__prices-prices">
              {laptopPrice.map((price, idx) => {
                return (
                  <div
                    key={idx}
                    className={`service__prices-price ${
                      isDarkTheme ? "" : "light"
                    }`}
                  >
                    <p className="service__prices-price-title light-text">
                      {price.title}
                    </p>
                    <p className="service__prices-price-price light-text">
                      {price.price}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="service__prices-texts">
              <p className="service__prices-text body-m">
                Ціни на ремонт MacBook (без урахування вартості запчастин):
              </p>
              <p className="service__prices-text body-m">Вартість</p>
            </div>
            {macArr.map((price, idx) => {
              return (
                <div
                  key={idx}
                  className={`service__prices-price ${
                    isDarkTheme ? "" : "light"
                  }`}
                >
                  <p className="service__prices-price-title light-text">
                    {price.title}
                  </p>
                  <p className="service__prices-price-price light-text">
                    {price.price}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="price-wrapper">
          <h2 onClick={handleComputerToggle} className="price-title body-m">
            Ремонт <span className="prices-special red-color">комп'ютерів</span>
            <div
              className={`triangle-price-phone ${
                isComputerActive ? "active" : ""
              }`}
            >
              <TrianglePrice isActive={isComputerActive} />
            </div>
          </h2>
          <div
            className={`table-price-wrapper ${
              isComputerActive ? "opened" : ""
            }`}
          >
            <div className="service__prices-prices">
              {computerArr.map((price, idx) => {
                return (
                  <div
                    key={idx}
                    className={`service__prices-price ${
                      isDarkTheme ? "" : "light"
                    }`}
                  >
                    <p className="service__prices-price-title light-text">
                      {price.title}
                    </p>
                    <p className="service__prices-price-price light-text">
                      {price.price}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="price-wrapper">
          <h2 onClick={handleTvToggle} className="price-title body-m">
            Ремонт <span className="prices-special red-color">моніторів та телевізорів</span>
            <div
              className={`triangle-price-phone ${isTvActive ? "active" : ""}`}
            >
              <TrianglePrice isActive={isTvActive} />
            </div>
          </h2>
          <div className={`table-price-wrapper ${isTvActive ? "opened" : ""}`}>
            <div className="service__prices-prices">
              {tvArr.map((price, idx) => {
                return (
                  <div
                    key={idx}
                    className={`service__prices-price ${
                      isDarkTheme ? "" : "light"
                    }`}
                  >
                    <p className="service__prices-price-title light-text">
                      {price.title}
                    </p>
                    <p className="service__prices-price-price light-text">
                      {price.price}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prices;
