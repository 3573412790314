import { useSelector } from 'react-redux';
import './ServiceDefects.css'

function ServiceDefects({defectsArr}) {
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
    
    return (
        <section className={`service__defects ${isDarkTheme ? "" : "light"}`}>
            <h2 className="service__defects-title body-m">Поширені <span className='red-color'>несправності</span></h2>
            <div className="service__defects-wrapper">
                {
                    defectsArr.map((defect, idx) => {
                        return (
                            <div key={idx} className="defect">
                                <img src={defect.src} alt={defect.description} className="defect__icon" />
                                <div className="defect__texts">
                                    <p className="defect__title body-m">{defect.title}</p>
                                    <div className="defect__description light-text">{defect.description}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default ServiceDefects;