import { useSelector } from "react-redux";
import "./Contacts.css";
import phoneIcon from '../../assets/icons/contact-icon-phone.png'
import geoIcon from '../../assets/icons/contact-icon-location.png'
import mailIcon from '../../assets/icons/contact-icon-mail.png'

function Contacts() {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  return (
    <div className="contacts__page">
      <h2 className="contacts__title body-m">
        Зв'язатися <span className="red-color">з нами</span>
      </h2>
      <div className="contacts__page-wrapper">
        <div className="contacts__page-phone">
        <img src={phoneIcon} alt="іконка телефону" className="contact__pjone-icon" />
          <div className="contacts__page-phone-title body-m">Телефон</div>
          <a
            className={`contacts__page-tel ${isDarkTheme ? "" : "light"} light-text`}
            href="tel:+380-67-297-51-67"
          >+380-67-297-51-67
          </a>
          <a
            className={`contacts__page-tel ${isDarkTheme ? "" : "light"} light-text`}
            href="tel:+380-50-297-51-67"
          >+380-50-297-51-67
          </a>
          <a
            className={`contacts__page-tel ${isDarkTheme ? "" : "light"} light-text`}
            href="tel:+380-73-297-51-67"
          >+380-73-297-51-67
          </a>
        </div>
        <div className="contacts__page-adress">
        <img src={geoIcon} alt="іконка телефону" className="contact__pjone-icon" />
            <p className="contacts__page-adress-title body-m">Де нас знайти</p>
            <p className="contacts__page-adress-adress light-text">вулиця Сергія Колачевського, 111А, Кривий Ріг, Дніпропетровська область</p>
        </div>
        <div className="contacts__page-mail">
        <img src={mailIcon} alt="іконка телефону" className="contact__pjone-icon" />
        <p className="contacts__page-adress-title body-m">Email</p>
        <a className={`contacts__page-mail-mail ${isDarkTheme ? "" : "light"} light-text`} href="mailto:interfaceid.consultant@gmail.com">interfaceid.consultant@gmail.com</a>
        </div>
      </div>
      <div className="contacts__map">
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2665.856331593023!2d33.509351576419405!3d48.07441597123661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dae32134a3d60d%3A0xbb5b3dcf5ef60167!2z0KHQtdGA0LLQuNGB0L3Ri9C5INGG0LXQvdGC0YAgSW50ZXJmYWNlSUQ!5e0!3m2!1sru!2sua!4v1717254102256!5m2!1sru!2sua" 
        width="100%" 
        height="100%" 
        style={{ border: 0 }} 
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
        title="відео нашої роботи"
      ></iframe>
      </div>
    </div>
  );
}

export default Contacts;
