import './MainPage.css'
import MainCover from "./MainCover/MainCover"
import MainAbout from './MainAbout/MainAbout'
import MainRepair from './MainRepair/MainRepair'
import MainVideo from './MainVideo/MainVideo'
import MainPluses from './MainPluses/MainPluses'
import MainFaq from './MainFaq/MainFaq'
import { accordionItems } from '../../utils/arrays'

function MainPage() {
    return (
        <div className="main__page">
            <MainCover />
            <MainAbout />
            <MainRepair />
            <MainVideo />
            <MainPluses />
            <MainFaq faqItems={accordionItems} />
        </div>
    )
}

export default MainPage;