import { useState } from "react";
import "./MainVideo.css";
import preview from "../../../assets/images/preview.webp";
import playIcon from "../../../assets/icons/play-icon.png";

function MainVideo(params) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <section className="main__video">
      {isPlaying ? (
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/PllqylG_HQg?si=Zp2PN3CIKYZ3U6O8&autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      ) : (
        <>
          <img
            src={preview}
            alt="превью до видео, на котором изображено процесс починки различных устройств"
            className="preview__image"
          />
          <img
            onClick={handlePlayVideo}
            src={playIcon}
            alt="кнопка яка запускає відео"
            className="play__icon"
          />
        </>
      )}
    </section>
  );
}

export default MainVideo;

