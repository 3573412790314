import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Burger from "../Burger/Burger";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Modal from "../Modal/Modal";
import "./AppRouter.css";

function AppRouter() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="global__container">
      <Header />
      <Burger />
      <main className="main">
        <Outlet />
      </main>
      <Modal />
      <Footer />
    </div>
  );
}

export default AppRouter;
