import { useSelector } from 'react-redux';
import './ServiceBeforeAfter.css'

const ServiceBeforeAfter = ({imageArr}) => {
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <div className={`service__before-after ${isDarkTheme ? "" : "light"}`}>
        <div className="service__before-after-wrapper">
            {
                imageArr.map((image, idx) => {
                    return (
                        <img key={idx} src={image} alt="фото до та після" className="before__after-image" />
                    )
                })
            }
        </div>
    </div>
  )
}

export default ServiceBeforeAfter