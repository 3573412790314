import "./MainCover.css";
import coverDark from "../../../assets/images/cover-dark.webp";
import coverLight from "../../../assets/images/cover-light.webp";
import Benefite from "./Benefite/Benefite";
import benefiteCoverOne from "../../../assets/icons/benefite-icon-one.webp";
import benefiteCoverTwo from "../../../assets/icons/benefite-icon-two.webp";
import benefiteCoverThree from "../../../assets/icons/benefite-icon-three.webp";
import { useDispatch, useSelector } from "react-redux";
import { setIsActive } from "../../../redux/ModalSlice/ModalSlice";
import { setBurgerIsClose } from "../../../redux/BurgerSlice/BurgerSlice";
import { useState } from "react";
import coverDarkMobile from '../../../assets/images/cover_dark_mobile.webp'
import coverLightMobile from '../../../assets/images/cover_light_mobile.webp'

function MainCover() {
    const isMobile = window.innerWidth <= 1199;
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.burger.isBurgerActive);
  const [isOpen, setOpen] = useState(isActive);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setBurgerIsClose());
    setOpen(false);
  };
  return (
    <div className="main__cover">
      <img
        src={ isDarkTheme ? isMobile ? coverDarkMobile : coverDark : isMobile ? coverLightMobile : coverLight}
        alt="обкладинка, на якій зображено процес ремонту телефону"
        className="main__wrapper-image"
      />
      <div className="main__cover-content">
        <div className="main__cover-content-wrapper">
          <h1
            className={`main__cover-title ${
              isDarkTheme ? "" : "light"
            } text-title-one`}
          >
            Interface<span className="red-color">id</span>
          </h1>
          <h2
            className={`main__cover-description ${
              isDarkTheme ? "" : "light"
            } text-title-two`}
          >
            Ваша техніка в надійних руках!
          </h2>
          <button onClick={handleModalOpen}
            className={`main__cover-button ${
              isDarkTheme ? "" : "light"
            } body-r`}
          >
            Зв'язатися з нами
          </button>
          <div className="main__cover-benefits">
            <Benefite
              isDarkTheme={isDarkTheme}
              src={benefiteCoverOne}
              title={"Швидкий ремонт"}
              description={"У той же день, діагностика від 30 хв."}
            />
            <Benefite
              isDarkTheme={isDarkTheme}
              src={benefiteCoverThree}
              title={"Гарантія"}
              description={"Гарантія нашої якості до 1 року"}
            />
            <Benefite
              isDarkTheme={isDarkTheme}
              src={benefiteCoverTwo}
              title={"Особистий підхід"}
              description={"Індивідуальний підхід до кожного пристрою/клієнта"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainCover;
