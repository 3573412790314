
function SunIcon({isDark}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_613_154)">
          <path d="M10.0003 14.4183C12.4405 14.4183 14.4186 12.4402 14.4186 10C14.4186 7.55986 12.4405 5.58173 10.0003 5.58173C7.56017 5.58173 5.58203 7.55986 5.58203 10C5.58203 12.4402 7.56017 14.4183 10.0003 14.4183Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M10.0001 15.1408C7.16552 15.1408 4.85938 12.8347 4.85938 10.0001C4.85938 7.16552 7.16552 4.85938 10.0001 4.85938C12.8347 4.85938 15.1408 7.16552 15.1408 10.0001C15.1408 12.8347 12.8347 15.1408 10.0001 15.1408ZM10.0001 6.30417C7.96223 6.30417 6.30427 7.96214 6.30427 10C6.30427 12.0379 7.96223 13.6959 10.0001 13.6959C12.038 13.6959 13.6959 12.0379 13.6959 10C13.6959 7.96214 12.038 6.30417 10.0001 6.30417Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M9.99979 3.7938C9.60081 3.7938 9.27734 3.47034 9.27734 3.07136V0.722446C9.27734 0.323463 9.60081 0 9.99979 0C10.3988 0 10.7222 0.323463 10.7222 0.722446V3.07136C10.7222 3.47034 10.3988 3.7938 9.99979 3.7938Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M9.99979 20C9.60081 20 9.27734 19.6765 9.27734 19.2776V16.9287C9.27734 16.5297 9.60081 16.2062 9.99979 16.2062C10.3988 16.2062 10.7222 16.5297 10.7222 16.9287V19.2776C10.7222 19.6766 10.3988 20 9.99979 20Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M19.2784 10.7224H16.9295C16.5305 10.7224 16.207 10.399 16.207 10C16.207 9.60102 16.5305 9.27756 16.9295 9.27756H19.2784C19.6774 9.27756 20.0008 9.60102 20.0008 10C20.0008 10.399 19.6775 10.7224 19.2784 10.7224Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M3.07136 10.7224H0.722446C0.323463 10.7224 0 10.399 0 10C0 9.60102 0.323463 9.27756 0.722446 9.27756H3.07136C3.47034 9.27756 3.7938 9.60102 3.7938 10C3.7938 10.399 3.47034 10.7224 3.07136 10.7224Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M14.9002 5.82311C14.7153 5.82311 14.5304 5.75259 14.3893 5.61148C14.1072 5.32934 14.1072 4.87188 14.3893 4.58984L16.0503 2.92899C16.3324 2.64685 16.7899 2.64675 17.0719 2.92899C17.3541 3.21113 17.3541 3.66858 17.0719 3.95062L15.411 5.61148C15.27 5.75259 15.085 5.82311 14.9002 5.82311Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M3.43922 17.2827C3.25437 17.2827 3.06942 17.2122 2.9284 17.0711C2.64626 16.789 2.64626 16.3315 2.9284 16.0495L4.58935 14.3885C4.87149 14.1064 5.32894 14.1064 5.61099 14.3885C5.89313 14.6707 5.89313 15.1281 5.61099 15.4101L3.95004 17.0711C3.80901 17.2122 3.62416 17.2827 3.43922 17.2827Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M16.5611 17.2827C16.3763 17.2827 16.1913 17.2122 16.0503 17.0711L14.3893 15.4101C14.1072 15.128 14.1072 14.6706 14.3893 14.3885C14.6715 14.1064 15.1289 14.1064 15.411 14.3885L17.0719 16.0495C17.3541 16.3316 17.3541 16.7891 17.0719 17.0711C16.9309 17.2122 16.7461 17.2827 16.5611 17.2827Z" fill={`${isDark ? "white" : "black"}`}/>
          <path d="M5.10017 5.82311C4.91532 5.82311 4.73037 5.75259 4.58935 5.61148L2.9284 3.95062C2.64626 3.66848 2.64626 3.21113 2.9284 2.92899C3.21054 2.64685 3.66799 2.64675 3.95004 2.92899L5.61099 4.58984C5.89313 4.87198 5.89313 5.32934 5.61099 5.61148C5.46997 5.75259 5.28512 5.82311 5.10017 5.82311Z" fill={`${isDark ? "white" : "black"}`}/>
        </g>
        <defs>
          <clipPath id="clip0_613_154">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    )
}

export default SunIcon