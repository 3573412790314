import "./Footer.css";
import footerLogo from "../../assets/images/logo.webp";
import footerLight from "../../assets/images/logo-light.png";
import instIcon from "../../assets/icons/inst-icon.png";
import tgIcon from "../../assets/icons/tg-icon.png";
import viberIcon from "../../assets/icons/viber-icon.png";
import ttIcon from "../../assets/icons/tt-icon.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PRICES_ROUTE,
  CONTACTS_ROUTE,
  ABOUTE_ROUTE,
  PHONE_TABLETS_ROUTE,
  LAPTOPS_ROUTE,
  COMPUTER_ROUTE,
  MONITORES_TV_ROUTE,
} from "../../utils/paths";

function Footer() {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <footer className={`footer ${isDarkTheme ? "" : "light"}`}>
      <div className="footer-wrapper">
        <div className="footer__contacts">
          <p className="footer__time red-color light-text">10:00-17:00</p>
          <div className="footer__phones">
            <a
              className={`footer__phone ${
                isDarkTheme ? "" : "light"
              } light-text`}
              href="tel:+380-67-297-51-67"
            >
              +380-67-297-51-67
            </a>
            <a
              className={`footer__phone ${
                isDarkTheme ? "" : "light"
              } light-text`}
              href="tel:+380-50-297-51-67"
            >
              +380-50-297-51-67
            </a>
            <a
              className={`footer__phone ${
                isDarkTheme ? "" : "light"
              } light-text`}
              href="tel:+380-73-297-51-67"
            >
              +380-73-297-51-67
            </a>
          </div>
          <a
            className={`footer__mail ${isDarkTheme ? "" : "light"} light-text`}
            href="mailto:interfaceid.consultant@gmail.com"
          >
            interfaceid.consultant@gmail.com
          </a>
          <p className="footer__adress light-text">
            Вулиця Сергія Колачевського, 111А, Кривий Ріг, Дніпропетровська
            область
          </p>
        </div>
        <div className="footer__media">
          <img
            src={isDarkTheme ? footerLogo : footerLight}
            alt="наш логотип"
            className="footer__logo"
          />
          <div className="footer__links">
            <a target={"_blank"} rel={"noreferrer"} href="https://www.instagram.com/interfaceid?igsh=MTV6d3BwNW9hYWtpbQ==" className="footer__link">
              <img
                src={instIcon}
                alt="іконка інстаграму"
                className="footer__link-icon"
              />
            </a>
            <a target={"_blank"} rel={"noreferrer"}
              href="https://t.me/InterfaceID"
              className="footer__link"
            >
              <img
                src={tgIcon}
                alt="іконка телеграму"
                className="footer__link-icon"
              />
            </a>
            <a target={"_blank"} rel={"noreferrer"} href="viber://chat?number=%2B380502975167" className="footer__link">
              <img
                src={viberIcon}
                alt="іконка вайберу"
                className="footer__link-icon"
              />
            </a>
            <a target={"_blank"} rel={"noreferrer"} href="https://www.tiktok.com/@interfaceid" className="footer__link">
              <img
                src={ttIcon}
                alt="іконка тіктоку"
                className="footer__link-icon"
              />
            </a>
          </div>
        </div>
        <div className="footer__pages">
          <p className="footer__pages-title body-m">Послуги</p>
          <div className="footer__pages-repair-list">
            <Link
              to={PHONE_TABLETS_ROUTE}
              className="footer__pages-repair-link light-text"
            >
              Телефони/Планшети
            </Link>
            <Link
              to={LAPTOPS_ROUTE}
              className="footer__pages-repair-link light-text"
            >
              Ноутбуки
            </Link>
            <Link
              to={COMPUTER_ROUTE}
              className="footer__pages-repair-link light-text"
            >
              Комп'ютери
            </Link>
            <Link
              to={MONITORES_TV_ROUTE}
              className="footer__pages-repair-link light-text"
            >
              Монітори/Телевізори
            </Link>
          </div>
          <Link to={ABOUTE_ROUTE} className="footer__pages-title-link body-m">
            Про нас
          </Link>
          <Link to={PRICES_ROUTE} className="footer__pages-title-link body-m">
            Ціни
          </Link>
          <Link to={CONTACTS_ROUTE} className="footer__pages-title-link body-m">
            Контакти
          </Link>
        </div>
      </div>
      <p className="footer__adress-mobile light-text">
        Вулиця Сергія Колачевського, 111А, Кривий Ріг, Дніпропетровська область
      </p>
    </footer>
  );
}

export default Footer;
