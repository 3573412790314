import { Link } from "react-router-dom";
import {
  COMPUTER_ROUTE,
  LAPTOPS_ROUTE,
  MONITORES_TV_ROUTE,
  PHONE_TABLETS_ROUTE,
} from "../../../utils/paths";
import "./MainRepair.css";
import repairIconOne from "../../../assets/icons/repair-icon-one.png";
import repairIconTwo from "../../../assets/icons/repair-icon-two.png";
import repairIconThree from "../../../assets/icons/repair-icon-three.png";
import repairIconFour from "../../../assets/icons/repair-icon-four.png";
import { useSelector } from "react-redux";

function MainRepair() {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  return (
    <section className="main__repair">
      <Link
        className={`main__repair-link ${isDarkTheme ? "" : "light"}`}
        to={PHONE_TABLETS_ROUTE}
      >
        <img
          src={repairIconOne}
          alt="перейти до сторінки ремонту телефонів та планшетів"
          className="main__repair-icon"
        />
        <div className="main__repair-texts">
          <h3
            className={`main__repair-title ${
              isDarkTheme ? "" : "light"
            } body-m`}
          >
            Телефони/Планшети
          </h3>
          <p className="main__repair-more red-color body-m">Детальніше </p>
        </div>
      </Link>
      <Link
        className={`main__repair-link ${isDarkTheme ? "" : "light"}`}
        to={LAPTOPS_ROUTE}
      >
        <img
          src={repairIconTwo}
          alt="перейти до сторінки ремонту ноутбуків"
          className="main__repair-icon"
        />
        <div className="main__repair-texts">
          <h3
            className={`main__repair-title ${
              isDarkTheme ? "" : "light"
            } body-m`}
          >
            Ноутбуки
          </h3>
          <p className="main__repair-more red-color body-m">Детальніше </p>
        </div>
      </Link>
      <Link
        className={`main__repair-link ${isDarkTheme ? "" : "light"}`}
        to={COMPUTER_ROUTE}
      >
        <img
          src={repairIconThree}
          alt="перейти до сторінки ремонту комп'ютерів"
          className="main__repair-icon"
        />
        <div className="main__repair-texts">
          <h3
            className={`main__repair-title ${
              isDarkTheme ? "" : "light"
            } body-m`}
          >
            Комп'ютери
          </h3>
          <p className="main__repair-more red-color body-m">Детальніше </p>
        </div>
      </Link>
      <Link
        className={`main__repair-link ${isDarkTheme ? "" : "light"}`}
        to={MONITORES_TV_ROUTE}
      >
        <img
          src={repairIconFour}
          alt="перейти до сторінки ремонту моніторів та телевізорів"
          className="main__repair-icon"
        />
        <div className="main__repair-texts">
          <h3
            className={`main__repair-title ${
              isDarkTheme ? "" : "light"
            } body-m`}
          >
            Монітори/Телевізори
          </h3>
          <p className="main__repair-more red-color body-m">Детальніше </p>
        </div>
      </Link>
    </section>
  );
}

export default MainRepair;
