import defectMobileOne from '../assets/icons/defect-mobile-one.png' 
import defectMobileTwo from '../assets/icons/defect-mobile-two.png' 
import defectMobileThree from '../assets/icons/defect-mobile-three.png' 
import defectMobileFour from '../assets/icons/defect-mobile-four.png' 
import defectMobileFive from '../assets/icons/defect-mobile-five.png' 
import defectMobileSix from '../assets/icons/defect-mobile-six.png' 
import defectLaptopOne from '../assets/icons/defect-laptop-one.png' 
import defectLaptopTwo from '../assets/icons/defect-laptop-two.png' 
import defectLaptopThree from '../assets/icons/defect-laptop-three.png' 
import defectLaptopFour from '../assets/icons/defect-laptop-four.png' 
import defectLaptopFive from '../assets/icons/defect-laptop-five.png' 
import defectLaptopSix from '../assets/icons/defect-laptop-six.png' 
import defectPcOne from '../assets/icons/defect-pc-one.png' 
import defectPcTwo from '../assets/icons/defect-pc-two.png' 
import defectPcThree from '../assets/icons/defect-pc-three.png' 
import defectPcFour from '../assets/icons/defect-pc-four.png' 
import defectPcFive from '../assets/icons/defect-pc-five.png' 
import defectPcSix from '../assets/icons/defect-pc-six.png' 
import defectTvOne from '../assets/icons/defect-tv-one.png' 
import defectTvTwo from '../assets/icons/defect-tv-two.png' 
import defectTvThree from '../assets/icons/defect-tv-three.png' 
import defectTvFour from '../assets/icons/defect-tv-four.png' 
import defectTvFive from '../assets/icons/defect-tv-five.png' 
import defectTvSix from '../assets/icons/defect-tv-six.png' 

export const accordionItems = [
    {
      title: "Як довго триває ремонт?",
      content: "Час ремонту залежить від конкретної поломки та складності робіт. Ми робимо все можливе, щоб виконати ремонт якнайшвидше і якісно. В більшості випадків, простіші ремонти можуть зайняти лише кілька годин, тоді як складніші поломки можуть зайняти більше часу.",
    },
    {
      title: "Ви займаєтесь налаштуванням телефонів?",
      content:
        "Ми залюбки допоможемо Вам з будь-якими проблемами у налаштуванні смартфону. Все, що Вам не зрозуміло, все, що не працює або працює не так, як повинно, ми налаштуємо! Також надамо консультацію та рекомендації щодо незрозумілих для Вас функцій.",
    },
    {
      title: "Чи надаєте ви консультації щодо догляду за технікою після ремонту?",
      content: `Звісно, ми надаємо всі необхідні рекомендації та вказівки після ремонту. Перші дії з новим акумулятором, що робити для збереження роз'єму живлення, які були причини поломки Вашого пристрою, та як цього запобігти у подальшому.`,
    },
    {
      title: "Як відстежувати статус ремонту мого пристрою?",
      content: `Для цього Вам необхідно буде відсканувати QR-код на квитанції прийому, яку ми Вам видамо. Ви отримаєте інформацію про дату та час, коли саме Ваш пристрій був у руках майстра, що було зроблено, коментарі та рекомендації.`,
    },
    {
      title: "Якщо пристрій неможливо відремонтувати, які опції ми пропонуємо?",
      content: `Відремонтувати можливо все! Але, на жаль, не завжди ремонт є рентабельним. Якщо відновлення пристрою не має сенсу, ми допоможемо вам із вибором нового пристрою, проконсультуємо щодо вигідних варіантів, надамо рекомендації, який пристрій краще придбати.`,
    },
    {
      title: "Надаємо послуги доставки і забору пристроїв?",
      content: `Ми пропонуємо "виклик майстра додому". Майстер проведе першу діагностику, вживаючи заходів для вирішення проблеми на місці. Якщо знадобиться, пристрій буде забрано до сервісного центру. Після відновлення майстер доставить його до вас.`,
    },
    {
      title: "Якщо мені не подобається результат ремонту, що робити?",
      content: `Майже неможливо, щоб ви були не задоволені нашою роботою. Але, якщо це все ж так трапилось, ви спокійно оглядаєте та перевіряєте пристрій при отриманні, і платите за послуги, лише якщо ви впевнені у якості наданих послуг.`,
    },
  ];
  
export const defectMobileArr = [
  {
  src: defectMobileOne,
  title: "Екран не реагує на дотики",
  description: "Необхідно перезавантажити пристрій, але на сенсорі відсутня можливість натиснути підтвердження? Нехай пристрій розрядиться повністю, потім вмикаєте його знову. Якщо проблема залишається, варто звернутися за діагностикою, включаючи перевірку шлейфів та конекторів.",
  },
  {
  src: defectMobileTwo,
  title: "Акумулятор швидко розряджається  ",
  description: "Переконайтеся, що всі програми закриті у фоновому режимі та спостерігайте за швидкістю розряджання. Якщо цей процес відбувається дуже швидко, негайно звертайтеся до нас для тестування акумулятора та, можливо, заміни його.",
  },
  {
  src: defectMobileThree,
  title: "Поганий звук під час дзвінків",
  description: "Перевірте налаштування гучності звуку. Якщо це не допомагає, можливо, потрібна чистка динаміка. Ця процедура зазвичай займає близько півгодини.",
  },
  {
  src: defectMobileFour,
  title: "Повільна робота пристрою",
  description: "Закрийте всі відкриті програми, видаліть зайві файли, звільніть оперативну пам'ять, а потім перезавантажте пристрій. Якщо ця діяльність не призводить до покращення, зверніться до нас для професійної очистки та налаштування.",
  },
  {
  src: defectMobileFive,
  title: "Вода потрапила в пристрій",
  description: "Якщо це можливо, негайно вимкніть пристрій. Якщо акумулятор можна вийняти, вийміть його та залиште пристрій сушитися протягом щонайменше 3 днів. У випадку, якщо акумулятор незнімний, важливо якомога швидше доставити пристрій до нашого сервісного центру для відключення акумулятора та проведення робіт з очищення плати та контактів від вологи. Варто уникати підключення до зарядного пристрою.",
  },
  {
  src: defectMobileSix,
  title: "Втрата даних",
  description: "Відновлення втрачених даних - це складний та індивідуальний процес. Ймовірність успішності становить близько 50%, і все залежить від конкретної ситуації та пристрою. Рекомендується регулярно робити резервне копіювання даних та використовувати автоматичну синхронізацію з хмарними платформами.",
  },
]

export const defectLaptopArr = [
  {
  src: defectLaptopOne,
  title: "Ноутбук не вмикається",
  description: "Відключіть живлення, вийміть акумулятор (якщо він зовнішній) та адаптер живлення. Підключіть адаптер та спробуйте вмикнути знову. Якщо не допомагає, тоді принесіть до нас.",
  },
  {
  src: defectLaptopTwo,
  title: "Ноутбук не заряджається",
  description: "Перевірте, чи правильно підключений адаптер живлення. Спробуйте інший адаптер або розетку. Якщо проблема з акумулятором, від'єднайте його та використовуйте ноутбук через адаптер.",
  },
  {
  src: defectLaptopThree,
  title: "Пошкоджений екран",
  description: `Ноутбук "працює", але немає зображення на екрані. Підключіть ноутбук до зовнішнього монітора, щоб перевірити, чи працює сам ноутбук. Якщо так, то можливо, потрібно замінити екран.`,
  },
  {
  src: defectLaptopFour,
  title: "Віруси та надокучлива реклама",
  description: "Використовуйте антивірусне програмне забезпечення для сканування та видалення вірусів. Оновіть операційну систему та всі програми.",
  },
  {
  src: defectLaptopFive,
  title: "Зависає або працює дуже повільно",
  description: "Закрийте всі непотрібні програми та процеси у фоновому режимі. Видаліть тимчасові файли та виконайте антивірусну перевірку. В разі необхідності, оновіть операційну систему.",
  },
  {
  src: defectLaptopSix,
  title: "Проблеми зі звуком",
  description: "Перевірте налаштування звуку на ноутбуку. Перезавантажте його та переконайтеся, що ви використовуєте правильні динаміки або навушники. В разі необхідності, оновіть аудіо драйвери.",
  },
]

export const defectPcArr = [
  {
  src: defectPcOne,
  title: "Проблеми з Запуском",
  description: "Відключіть живлення на декілька хвилин, потім підключіть та спробуйте вмикнути знову. Якщо не допомагає, тоді принесіть до нас.",
  },
  {
  src: defectPcTwo,
  title: "Дуже гучно працює (ШУМ)",
  description: "Перевірте, чи не забруднені вентилятори всередині системного блоку, прочистіть їх пензликом та продуйте повітрям. Якщо шум не зник, тоді принесіть до нас для професійного обслуговування.",
  },
  {
  src: defectPcThree,
  title: "Немає зображення на екрані.",
  description: ` Перевірте кабель, який йде до монітора, та, по можливості, підключіть інший монітор чи телевізор. Якщо на іншому екрані працює, то проблема, можливо, у вашому моніторі, якщо ні, то у системному блоку.`,
  },
  {
  src: defectPcFour,
  title: "Віруси та надокучлива реклама",
  description: "Використовуйте антивірусне програмне забезпечення для сканування та видалення вірусів. Оновіть операційну систему та всі програми.",
  },
  {
  src: defectPcFive,
  title: "Зависання та повільна робота",
  description: "Закрийте всі непотрібні програми та процеси у фоновому режимі. Видаліть тимчасові файли та виконайте антивірусну перевірку. В разі необхідності, оновіть операційну систему.",
  },
  {
  src: defectPcSix,
  title: "Погано працює мишка чи клавіатура",
  description: "Рекомендується витягнути шнур підключення та вставити його у інший роз'єм. Таким чином, ви переконаєтесь, чи є проблема з зовнішнім пристроєм чи у самому роз'ємі підключення.",
  },
]

export const defectTvArr = [
  {
  src: defectTvOne,
  title: "Телевізор не вмикається",
  description: "Відключіть живлення на декілька хвилин, потім підключіть та спробуйте вмикнути знову. Якщо не допомагає, тоді принесіть до нас.",
  },
  {
  src: defectTvTwo,
  title: "Проблема з зображенням",
  description: "Якщо проблема полягає в кольорах, перевірте налаштування насиченості та відтінків. Якщо це не допомагає, або у вас з'являються яскраві полоси на екрані, можливо, потрібна заміна матриці.",
  },
  {
  src: defectTvThree,
  title: "Немає зображення на екрані",
  description: `Просвітіть ліхтариком на екран, якщо бачите зображення, то потрібна заміна підсвітки. Якщо зображення немає, то проблема може бути у самій матриці або живленні та інших частинах.`,
  },
  {
  src: defectTvFour,
  title: "Вмикається і відразу вимикається",
  description: "Ця проблема часто пов'язана з блоком живлення. Якщо це зовнішній блок, ви можете спробувати замінити його самостійно. Якщо він внутрішній, ми вам допоможемо.",
  },
  {
  src: defectTvFive,
  title: "Зависання та повільна робота",
  description: "Оновіть операційну систему, видаліть зайві програми. Якщо це не допомагає, шукайте інформацію в Інтернеті за моделлю вашого телевізора, можливо, він потребує оновлення.",
  },
  {
  src: defectTvSix,
  title: "Проблеми зі звуком",
  description: "Перевірте, чи не забруднені решітки на корпусі, де звучить звук. Якщо тріщить, то можлива заміна динаміків.",
  },
]

export const phoneTabletPrice = [
  {title: "Діагностика (при умові подальшого ремонту)",
   price: "Безкоштовно"
  },
  {title: "Налаштування",
   price: "від 50грн"
  },
  {title: "Відновлення АКБ",
   price: "350грн"
  },
  {title: "Заміна Дисплейного модуля на новий",
   price: "від 600грн"
  },
  {title: "Переклейка Вашого робочого модуля",
   price: "700грн"
  },
  {title: "Заміна роз'єму живленя microUSB (з урахуванням вартості запчастин) - заміна стандарт",
   price: "500грн"
  },
  {title: "Заміна роз'єму живленя Type-C (з урахуванням вартості запчастин) - заміна стандарт",
   price: "600грн"
  },
  {title: "Заміна мікрофона (з урахуванням вартості запчастин)",
   price: "550грн"
  },
  {title: "Заміна роз'єма SIM карти стандарт",
   price: "600-800грн"
  },
  {title: "Заміна акумулятору (АКБ)",
   price: "400-800грн"
  },
  {title: "Заміна Динаміку",
   price: "450-800грн"
  },
  {title: "Чистка динаміків",
   price: "250грн"
  },
  {title: "Чистка роз'єму живлення",
   price: "250грн"
  },
  {title: "Прошивка стандарт",
   price: "600грн"
  },
  {title: "Скидання паролю екрана",
   price: "450грн"
  },
  {title: "Скидання паролю Google (Гугл АКК)",
   price: "800-1200грн"
  },
  {title: "Розблокування Мі Аккаунту",
   price: "1500грн"
  },
  {title: "Розблокування Samsung аккаунту",
   price: "1500грн"
  },
  {title: "Прошивка программатором (якщо апаратний збій)",
   price: "1000грн"
  },
  {title: "Ремонт контролеру живлення (КП) (з урахуванням вартості запчастин)",
   price: "1200-1500грн"
  },
  {title: "Заміна флеш пам'яті / процесору",
   price: "від 1500грн"
  },
  {title: "Чистка та відновлення плати, після потрапляння вологи",
   price: "1000грн"
  },
  {title: "Заміна задньої кришки телефона",
   price: "200грн"
  },
  {title: "Заміна скла камери",
   price: "100грн"
  },
  {title: "Заміна конектору на платі",
   price: "500грн"
  },
]

export const laptopPrice = [
  {title: "Діагностика (при умові подальшого ремонту)",
   price: "Безкоштовно"
  },
  {title: "Встановлення Віндовс 10 / 11",
   price: "450грн"
  },
  {title: "Повне налаштування та оптимізація після встановлення віндовс",
   price: "200грн"
  },
  {title: "Збереження інформації (до 200гб)",
   price: "200грн"
  },
  {title: "Збереження інформації (від 200гб)",
   price: "350грн"
  },
  {title: "Чистка та заміна термопасти",
   price: "500-800грн"
  },
  {title: "Термопрокладки (додатково до термопасти)",
   price: "100-400грн"
  },
  {title: "Заміна Комплектуючих (1шт)",
   price: "100грн"
  },
  {title: "Заміна клавіатури стандарт (ноут)",
   price: "100грн"
  },
  {title: "Заміна клавіатури (впаяна)",
   price: "800грн"
  },
  {title: "Заміна роз'єма живлення",
   price: "800грн"
  },
  {title: "Заміна конектору (роз'єм)",
   price: "500-800грн"
  },
  {title: "Відновлення схеми живлення",
   price: "від 800грн"
  },
  {title: "Заміна матриці",
   price: "700грн"
  },
  {title: "Заміна мікросхем",
   price: "від 1500грн"
  },
  {title: "Чистка та відновлення контактів після вологи",
   price: "1000грн"
  },
  {title: "Комплексний ремонт залитого (після вологи)",
   price: "від 1500грн"
  },
  {title: "Налаштування біоса",
   price: "400грн"
  },
  {title: "Прошивка біос (без программатора)",
   price: "800грн"
  },
  {title: "Прошивка біос (з программатором)",
   price: "від 1200грн"
  },
  {title: "Ремонт БП",
   price: "від 450грн"
  },
]

export const computerPrice = [
  {title: "Діагностика (при умові подальшого ремонту)",
   price: "Безкоштовно"
  },
  {title: "Встановлення Віндовс 10 / 11",
   price: "450грн"
  },
  {title: "Повне налаштування та оптимізація після встановлення віндовс",
   price: "200грн"
  },
  {title: "Збереження інформації (до 200гб)",
   price: "200грн"
  },
  {title: "Збереження інформації (від 200гб)",
   price: "350грн"
  },
  {title: "Чистка та заміна термопасти",
   price: "від 500грн"
  },
  {title: "Термопрокладки (додатково до термопасти)",
   price: "100грн"
  },
  {title: "Обслуговування відеокарти",
   price: "250-800грн"
  },
  {title: "Заміна Комплектуючих (1шт)",
   price: "100грн"
  },
  {title: "Заміна конектору (роз'єм)",
   price: "500-800грн"
  },
  {title: "Відновлення схеми живлення",
   price: "від 800грн"
  },
  {title: "Заміна мікросхем",
   price: "від 1200грн"
  },
  {title: "Чистка та відновлення контактів після вологи",
   price: "1000грн"
  },
  {title: "Налаштування біоса",
   price: "400грн"
  },
  {title: "Прошивка біос (без программатора)",
   price: "600грн"
  },
  {title: "Прошивка біос (з программатором)",
   price: "від 1000грн"
  },
  {title: "Ремонт БП",
   price: "від 450грн"
  },
  {title: "Повна збірка ПК з підбором комплектуючих",
   price: "1000грн"
  },
]

export const screenPrice = [
  {title: "Діагностика (при умові подальшого ремонту)",
   price: "Безкоштовно"
  },
  {title: "Прошивка",
   price: "від 800грн"
  },
  {title: "Повне налаштування та оптимізація, з викликом додому",
   price: "800грн"
  },
  {title: "Чистка та обслуговування",
   price: "від 800грн"
  },
  {title: "Термопрокладки (додатково до термопасти)",
   price: "200грн"
  },
  {title: "Заміна конектору (роз'єм)",
   price: "800грн"
  },
  {title: "Відновлення схеми живлення",
   price: "від 800грн"
  },
  {title: "Заміна мікросхем",
   price: "від 1200грн"
  },
  {title: "Чистка та відновлення контактів після вологи",
   price: "від 800грн"
  },
  {title: "Ремонт БП",
   price: "від 450грн"
  },
  {title: "Заміна матриці",
   price: "від 700грн"
  },
  {title: "Заміна підсвітки",
   price: "від 1200грн"
  },
]

export const iphonePrice = [
  {title: "Діагностика (при умові подальшого ремонту)",
   price: "Безкоштовно"
  },
  {title: "Заміна модуля",
   price: "800грн"
  },
  {title: "Заміна сенсору (без заміни дисплею)",
   price: "від 2000грн"
  },
  {title: "Заміна роз'єму живлення/мікрофону",
   price: "800-1200грн"
  },
  {title: "Чистка динаміків без розбору",
   price: "250грн"
  },
  {title: "Чистка динаміків з заміною сітки (з урахуванням вартості запчастин)",
   price: "800-1200грн"
  },
  {title: "Створення Apple ID та налаштування під ключ",
   price: "400-800грн"
  },
  {title: "Перенесення даних з Android на iOS",
   price: "600-800грн"
  },
  {title: "Заміна акумулятору (АКБ)",
   price: "400-1200грн"
  },
  {title: "Ремонт FaceID",
   price: "1500-2500грн"
  },
  {title: "Аудіо-Кодек",
   price: "1200-1800грн"
  },
  {title: "Відновлення після вологи",
   price: "1000-1500грн"
  },
  {title: "Відновлення герметичності",
   price: "600грн"
  },
  {title: "Ремонт по схемі живлення",
   price: "від 1200грн"
  },
  {title: "Заміна задньої кришки",
   price: "800-1200грн"
  },
  {title: "Заміна скла камери",
   price: "400грн"
  },
  {title: "Прошивка",
   price: "600-1200грн"
  },
  {title: "Скидання Apple ID",
   price: "2000-12000грн"
  },
]

export const macBookPrice = [
  {title: "Діагностика",
   price: "200грн"
  },
  {title: "Встановлення ОС",
   price: "800грн"
  },
  {title: "Повне налаштування та оптимізація після встановлення ОС",
   price: "200грн"
  },
  {title: "Збереження інформації (до 200гб)",
   price: "200грн"
  },
  {title: "Збереження інформації (від 200гб)",
   price: "350грн"
  },
  {title: "Чистка та заміна термопасти",
   price: "800грн"
  },
  {title: "Термопрокладки (додатково до термопасти)",
   price: "400грн"
  },
  {title: "Заміна Комплектуючих (1шт)",
   price: "500грн"
  },
  {title: "Заміна клавіатури",
   price: "1200грн"
  },
  {title: "Заміна роз'єма живлення",
   price: "1000грн"
  },
  {title: "Заміна конектору (роз'єм)",
   price: "800-1200грн"
  },
  {title: "Відновлення схеми живлення",
   price: "від 1500грн"
  },
  {title: "Заміна матриці",
   price: "1200грн"
  },
  {title: "Заміна мікросхем",
   price: "від 1500грн"
  },
  {title: "Чистка та відновлення контактів після вологи",
   price: "1500грн"
  },
  {title: "Комплексний ремонт залитого (після вологи)",
   price: "від 2500грн"
  },
  {title: "Ремонт БП",
   price: "від 600грн"
  },
]
