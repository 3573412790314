import "./Modal.css";
import closeButton from "../../assets/icons/closeButton.png";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useTelegramMessage } from "../../utils/request";
import { setClearIsFormSended, setIsClose } from "../../redux/ModalSlice/ModalSlice";
import InputMask from "react-input-mask";
import { useEffect } from "react";
import responseIcon from '../../assets/icons/response-icon.png'


function Modal() {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.modal.isFormSended);
  let timeoutId;
  const [isResponseActive, setIsResponseActive] = useState(false);
  const [userName, setUserName] = useState("");
  const [userContact, setUserContact] = useState("");
  const [nameError, setNameError] = useState(false);
  const [ContactError, setContactError] = useState(false);
  const isActive = useSelector((state) => state.modal.isActive);
  const { sendTelegramMessage } = useTelegramMessage()

  useEffect(() => {
    setIsResponseActive(response);

    if (isResponseActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormSended());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isResponseActive, dispatch]);
  
  const handleModalClose = () => {
    dispatch(setIsClose());
    dispatch(setClearIsFormSended())
  };

  const handleModalBodyClick = (e) => {
    e.stopPropagation();
  };

  const handleContactChange = (e) => {
    setUserContact(e.target.value);
    setContactError(false)
  };

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let nameErrorFlag = false;
    let phoneErrorFlag = false;
    
    if (userName.length < 1) {
        setNameError(true);
        nameErrorFlag = true;
      } else {
        setNameError(false);
      }

      if (userContact && !userContact.includes("_")) {
        setContactError(false);
      } else {
        setContactError(true);
        phoneErrorFlag = true;
      }

      if (nameErrorFlag || phoneErrorFlag) {
      return;
    } else if (!nameErrorFlag && !phoneErrorFlag) {
        const message = `\u{2753}Консультація\u{2753}\nИмя: ${userName}\nКонтакт: ${userContact}`;
        setUserName("");
        setUserContact("");
        await sendTelegramMessage(message);
    }
  };

  return (
    <div className={`modal__form ${isActive ? "active" : ""}`}>
      <div onClick={handleModalClose} className="modal__overlay">
        <div onClick={handleModalBodyClick} className={`modal__form-body ${isResponseActive ? "hidden" : ""}`}>
          <p className="modal__form-title light-text">
          Натисни і дзвони
            <img
              onClick={handleModalClose}
              src={closeButton}
              alt="закрити форму"
              className="close__button"
            />
          </p>
          <a className="modal__form-phone body-m" href="tel:+380-67-297-51-67">+380-67-297-51-67</a>
          <p className="body-r">АБО</p>
          <div className="modal__form-description light-text">Введіть своє ім’я і номер телефону і ми з вами зв’яжемось найближчим часом  </div>
          <input
            placeholder="Ім’я"
            value={userName}
            onChange={handleNameChange}
            className={`modal__form-input ${
              nameError ? "error" : ""
            } light-text`}
            type="text"
          />
           <InputMask
                mask="+380 (99) 999-99-99"
                value={userContact}
                onChange={handleContactChange}
                placeholder="Номер телефону"
                onFocus={(e) => e.target.setSelectionRange(6, 6)}
                onBlur={(e) => {
                  if (e.target.value === "+380 ()") {
                    setUserContact("");
                  }
                }}
                className={`modal__form-input ${
                    ContactError ? "error" : ""
                  } light-text`}
                type="phone"
              />
          <div
            onClick={handleSubmit}
            className={`modal__form-button body-r`}
            type="submit"
          >
            Передзвоніть мені
          </div>
        </div>
        <div onClick={handleModalBodyClick} className={`modal__form-response ${isResponseActive ? "active" : ""}`}>
            <img src={responseIcon} alt="форма успішно відправлена" className="response__icon" />
            <p className="response__title text-title-one">Форма успішно відправлена
            <img
              onClick={handleModalClose}
              src={closeButton}
              alt="закрити форму"
              className="close__button-response"
            />
            </p>
            <p className="response__description text-title-one">Зателефонуємо вам найближчим часом</p>
        </div>
      </div>
    </div>
  );
}

export default Modal;
