import differencePhoneOne from '../assets/images/image 142.webp'
import differencePhoneTwo from '../assets/images/image 143.webp'
import differencePhoneThree from '../assets/images/image 144.webp'
import differencePhoneFour from '../assets/images/image 145.webp'
import differenceLaptopOne from '../assets/images/image 146.webp'
import differenceLaptopTwo from '../assets/images/image 147.webp'
import differenceLaptopThree from '../assets/images/image 148.webp'
import differenceLaptopFour from '../assets/images/image 149.webp'
import differencePcOne from '../assets/images/image 138.webp'
import differencePcTwo from '../assets/images/image 139.webp'
import differencePcThree from '../assets/images/image 140.webp'
import differencePcFour from '../assets/images/image 141.webp'
import differenceTvOne from '../assets/images/image 150.webp'
import differenceTvTwo from '../assets/images/image 151.webp'

export const differencePhone = [
    differencePhoneOne,
    differencePhoneTwo,
    differencePhoneThree,
    differencePhoneFour,
  ]
export const differenceLaptop = [
    differenceLaptopOne,
    differenceLaptopTwo,
    differenceLaptopThree,
    differenceLaptopFour,
  ]
export const differencePc = [
    differencePcOne,
    differencePcTwo,
    differencePcThree,
    differencePcFour,
  ]
export const differenceTv = [
    differenceTvOne,
    differenceTvTwo,
  ]