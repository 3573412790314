import ServiceBeforeAfter from './ServiceBeforeAfter/ServiceBeforeAfter'
import ServiceDefects from './ServiceDefects/ServiceDefects'
import './ServicePage.css'
import ServicePrices from './ServicePrices/ServicePrices'

function ServicePage({serviceTitle, serviceDescriptionOne, serviceDescriptionTwo, defectsArr, priceArr, isIphone, isMac, iphoneArr, MacArr, imageArr}) {
    return (
        <div className="service__page">
            <h1 className="service__page-title body-m">Ремонт <span className='red-color'>{serviceTitle}</span></h1>
            <div className="service__page-description-wrapper">
            <p className="service__page-description light-text">{serviceDescriptionOne}</p>
            <p className="service__page-description light-text">{serviceDescriptionTwo}</p>
            </div>
            <ServiceDefects defectsArr={defectsArr} />
            <ServicePrices priceArr={priceArr} isIphone={isIphone} isMac={isMac} iphoneArr={iphoneArr} MacArr={MacArr} />
            <ServiceBeforeAfter imageArr={imageArr} />
        </div>
    )
}

export default ServicePage