import './AboutUsValues.css'
import valueIconOne from '../../../assets/icons/value-icon.png'
import valueIconTwo from '../../../assets/icons/value-icon-two.png'
import valueIconThree from '../../../assets/icons/value-icon-three.png'
import { useSelector } from 'react-redux';

function AboutUsValues() {
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

    return (
        <section className={`about__values ${isDarkTheme ? "" : "light"}`}>
            <h2 className="about__values-title body-m">Наші <span className='red-color'>цінності</span></h2>
            <div className="about__values-wrapper">
                <div className="about__value">
                    <img src={valueIconOne} alt="іконка яка зображає нашу конкретну цінність" className="about__value-icon" />
                    <h3 className="about__value-title body-m">Прозорість</h3>
                    <p className="about__value-description light-text">Відверто та без приховань. Ви заслуговуєте знати всю інформацію про ваш ремонт.</p>
                </div>
                <div className="about__value">
                    <img src={valueIconTwo} alt="іконка яка зображає нашу конкретну цінність" className="about__value-icon" />
                    <h3 className="about__value-title body-m">Задоволеність клієнта</h3>
                    <p className="about__value-description light-text">Ваша радість - наш успіх. Ми задовольнені, коли ви задоволені.</p>
                </div>
                <div className="about__value">
                    <img src={valueIconThree} alt="іконка яка зображає нашу конкретну цінність" className="about__value-icon" />
                    <h3 className="about__value-title body-m">Співпраця</h3>
                    <p className="about__value-description light-text">Разом - краще. Ваші ідеї та наш досвід створюють ідеальний синергійний ефект.</p>
                </div>
            </div>
        </section>
    )
}

export default AboutUsValues