import { useSelector } from "react-redux";


function TrianglePrice({ isActive }) {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
  <path d="M6.5 12L0.00480991 0.749998L12.9952 0.75L6.5 12Z" fill={`${isActive ? "red" : isDarkTheme? "white" : "black"}`}/>
</svg>
  );
}

export default TrianglePrice;