import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import { setIsActive } from "../../redux/ModalSlice/ModalSlice";
import { toggleTheme } from "../../redux/ToggleThemeSlice/ToggleThemeSlice";
import {
  ABOUTE_ROUTE,
  COMPUTER_ROUTE,
  CONTACTS_ROUTE,
  LAPTOPS_ROUTE,
  MONITORES_TV_ROUTE,
  PHONE_TABLETS_ROUTE,
  PRICES_ROUTE,
} from "../../utils/paths";
import ToggleThemeButton from "../ToggleThemeButton/ToggleThemeButton";
import "./Burger.css";

function Burger() {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.burger.isBurgerActive);
  const [isOpen, setOpen] = useState(isActive);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const [isChecked, setIsChecked] = useState(isDarkTheme);

  const handleBurgerClose = () => {
    dispatch(setBurgerIsClose());
  };

  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setBurgerIsClose());
    setOpen(false);
  };

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
    console.log("duck");
    setIsChecked((prev) => !prev);
  };

  useEffect(() => {
    setIsChecked(isDarkTheme);
    setOpen(isActive);
  }, [isDarkTheme, isActive]);

  return (
    <div className={`burger__menu ${isActive ? "active" : ""}`}>
      <div className={`burger__overlay ${isDarkTheme ? "" : "light"}`}>
        <div className="burger__body">
          <div className="burger__body-repair">
            <div className="burger__body-repair-title light-text red-color">
              Ремонт
            </div>
            <Link
              onClick={handleBurgerClose}
              className={`burger__link-repair ${isDarkTheme ? "" : "light"} light-text`}
              to={PHONE_TABLETS_ROUTE}
            >
              Телефони/Планшети
            </Link>
            <Link
              onClick={handleBurgerClose}
              className={`burger__link-repair ${isDarkTheme ? "" : "light"} light-text`}
              to={LAPTOPS_ROUTE}
            >
              Ноутбуки
            </Link>
            <Link
              onClick={handleBurgerClose}
              className={`burger__link-repair ${isDarkTheme ? "" : "light"} light-text`}
              to={COMPUTER_ROUTE}
            >
              Комп'ютери
            </Link>
            <Link
              onClick={handleBurgerClose}
              className={`burger__link-repair ${isDarkTheme ? "" : "light"} light-text`}
              to={MONITORES_TV_ROUTE}
            >
              Монітори/Телевізори
            </Link>
          </div>
          <Link
            onClick={handleBurgerClose}
            className={`burger__link ${isDarkTheme ? "" : "light"} light-text`}
            to={ABOUTE_ROUTE}
          >
            Про нас
          </Link>
          <Link
            onClick={handleBurgerClose}
            className={`burger__link ${isDarkTheme ? "" : "light"} light-text`}
            to={PRICES_ROUTE}
          >
            Ціни
          </Link>
          <Link
            onClick={handleBurgerClose}
            className={`burger__link ${isDarkTheme ? "" : "light"} light-text`}
            to={CONTACTS_ROUTE}
          >
            Контакти
          </Link>
          <button
            onClick={handleModalOpen}
            className={`burger__button-menu ${isDarkTheme ? "" : "light"} body-r`}
          >
            Зв'язатися з нами
          </button>
          <div
            onClick={handleToggleTheme}
            className="toggle__header-button-wrapper-mobile"
          >
            <ToggleThemeButton isChecked={!isChecked} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Burger;
