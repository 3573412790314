import "./ToggleThemeButton.css";

import LunaIcon from "./LunaIcon/LunaIcon";
import SunIcon from "./SunIcon/SunIcon";

function ToggleThemeButton({ isChecked }) {
  return (
    <div className="theme__button">
      <div className={`theme__button-wrapper  ${isChecked ? "checked" : ""}`}>
        <div className={`theme__button-thumbler ${isChecked ? "checked" : ""}`}></div>
        <div className="theme__icon-wrapper">
          <LunaIcon isDark={true} />
        </div>
        <div className="theme__icon-wrapper">
          <SunIcon isDark={true} />
        </div>
      </div>
    </div>
  );
}

export default ToggleThemeButton;
