import { useSelector } from "react-redux";
import "./AboutTeam.css";

function AboutTeam() {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <section className="about__team">
      <h2 className="about__team-title body-m">
        Наша <span className="red-color">команда</span>
      </h2>
      <p className="about__team-description light-text">
        Кожен член нашої команди присвятив себе високоякісному ремонту та
        відновленню техніки. Ми пишаємося нашим досвідом та здатністю знаходити
        ефективні рішення для навіть найскладніших завдань.
      </p>
      <div className="about__team-wrapper">
        <div className={`about__team-part ${isDarkTheme ? "" : "light"}`}>
          <p className="about__team-part-name body-m">Анастаія</p>
          <p className="about__team-part-job red-color body-m">
            Менеджер з роботи з клієнтами
          </p>
          <p className="about__team-part-description light-text">
            Чистка та налаштування ПО телефонів, консультації та спілкування -
            це основні завдання, які Анастасія залюбки та з великою
            професійністю виконує. Саме вона приймає Вашу техніку на ремонт, а
            згодом видає вже відремонтовану. Знає усі тонкості у своїй справі.
          </p>
        </div>
        <div className={`about__team-part ${isDarkTheme ? "" : "light"}`}>
          <p className="about__team-part-name body-m">Євгеній</p>
          <p className="about__team-part-job red-color body-m">
            Майстер ремонту техніки
          </p>
          <p className="about__team-part-description light-text">
            Євгеній є видатним фахівцем у відновленні техніки, діагностиці,
            паянні та налаштуванні. З понад 10-річним досвідом у даній сфері,
            він впевнено долає будь-які тонкощі та завдання, професійно
            виконуючи свої обов'язки.
          </p>
        </div>
        <div className={`about__team-part ${isDarkTheme ? "" : "light"}`}>
          <p className="about__team-part-name body-m">Сергій</p>
          <p className="about__team-part-job red-color body-m">IT-спеціаліст</p>
          <p className="about__team-part-description light-text">
            Сергій майстерно налаштовує операційні системи, відновлює дані в
            обмежених ситуаціях та робить завдання, що недосяжні для інших. З
            понад 20-річним досвідом у сфері IT, він є експертом у встановленні
            драйверів, роботі з біосом, прошивці роутерів, відеоспостереженні та
            програмах 1С.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutTeam;
