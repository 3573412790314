import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AppRouter from './components/AppRouter/AppRouter';
import AboutUs from './pages/AboutUs/AboutUs';
import Contacts from './pages/Contacts/Contacts';
import MainPage from './pages/MainPage/MainPage';
import Prices from './pages/Prices/Prices';
import ServicePage from './pages/ServicePage/ServicePage';
import { setDarkTheme, setLightTheme } from './redux/ToggleThemeSlice/ToggleThemeSlice';
import { computerPrice, defectLaptopArr, defectMobileArr, defectPcArr, defectTvArr, iphonePrice, laptopPrice, macBookPrice, phoneTabletPrice, screenPrice } from './utils/arrays';
import { differenceLaptop, differencePc, differencePhone, differenceTv } from './utils/beforeAfter';
import { ABOUTE_ROUTE, COMPUTER_ROUTE, CONTACTS_ROUTE, LAPTOPS_ROUTE, MONITORES_TV_ROUTE, PHONE_TABLETS_ROUTE, PRICES_ROUTE } from './utils/paths';

function App() {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const dispatch = useDispatch();


  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = (e) => {
      if (e.matches) {
        dispatch(setDarkTheme());
      } else {
        dispatch(setLightTheme());
      }
    };

    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, [dispatch]);

  return (
    <div className={`App ${isDarkTheme ? "" : "light"}`}>
      <Router>
        <Routes>
          <Route path="/" element={<AppRouter />}>
          <Route index element={<MainPage />} />
          <Route path={ABOUTE_ROUTE} element={<AboutUs />} />
          <Route path={CONTACTS_ROUTE} element={<Contacts />} />
          <Route path={PRICES_ROUTE} element={<Prices phoneArr={phoneTabletPrice} iphoneArr={iphonePrice} laptopPrice={laptopPrice} computerArr={computerPrice} tvArr={screenPrice} macArr={macBookPrice} />} />
          <Route path={PHONE_TABLETS_ROUTE} element={<ServicePage serviceTitle={"телефонів та планшетів"} serviceDescriptionOne={"Наш сервіс з ремонту телефонів та планшетів пропонує вам якісний та оперативний підхід до відновлення вашої електроніки. Незалежно від проблеми, з якою ви зіштовхнулись - починаючи від пошкодженого екрану і закінчуючи проблемами з програмним забезпеченням - наша команда досвідчених фахівців з радістю вам допоможе."} serviceDescriptionTwo={"Ми розуміємо, як важливий для вас ваш телефон чи планшет. Тому ми зосереджуємося на швидкому та надійному відновленні вашого пристрою. Наші професіонали володіють унікальним досвідом у ремонті техніки різних виробників та моделей, що дозволяє нам ефективно долати різноманітні проблеми."} imageArr={differencePhone} defectsArr={defectMobileArr} priceArr={phoneTabletPrice} isIphone={true} iphoneArr={iphonePrice} />} />
          <Route path={LAPTOPS_ROUTE} element={<ServicePage serviceTitle={"ноутбуків"} serviceDescriptionOne={"Наш сервіс з ремонту ноутбуків пропонує вам якісний та оперативний підхід до відновлення вашої техніки. Незалежно від проблеми, з якою ви зіштовхнулись - починаючи від несправностей апаратної частини до проблем з програмним забезпеченням - наша команда досвідчених фахівців готова вам допомогти."} serviceDescriptionTwo={"Ми розуміємо, як важливий для вас ваш ноутбук. Тому ми приділяємо особливу увагу швидкому та надійному відновленню вашого пристрою. Наші професіонали мають багаторічний досвід у ремонті ноутбуків різних виробників та моделей, що дозволяє нам ефективно вирішувати навіть найскладніші завдання."} imageArr={differenceLaptop} defectsArr={defectLaptopArr} priceArr={laptopPrice} isMac={true} MacArr={macBookPrice}/>}/>
          <Route path={COMPUTER_ROUTE} element={<ServicePage serviceTitle={"комп'ютерів"} serviceDescriptionOne={"Наш сервіс з ремонту комп'ютерів та ноутбуків пропонує вам якісний та оперативний підхід до відновлення вашої електроніки. Незалежно від проблеми, з якою ви зіткнулися - починаючи від апаратних несправностей до складних вірусних атак - наша команда досвідчених фахівців з радістю вам допоможе."} imageArr={differencePc} serviceDescriptionTwo={"Ми розуміємо, як важливий для вас ваш комп'ютер чи ноутбук. Тому ми зосереджуємося на швидкому та надійному відновленні вашого пристрою. Наші професіонали володіють унікальним досвідом у ремонті техніки різних виробників та моделей, що дозволяє нам ефективно долати різноманітні проблеми, від заміни комплектуючих до відновлення операційних систем."} defectsArr={defectPcArr} priceArr={computerPrice} />} />
          <Route path={MONITORES_TV_ROUTE} element={<ServicePage serviceTitle={"моніторів та телевізорів"} serviceDescriptionOne={"Наш сервіс з ремонту моніторів та телевізорів пропонує вам якісний та оперативний підхід до відновлення вашої електроніки. Незалежно від проблеми, з якою ви зіткнулися - починаючи від несправностей в екрані та закінчуючи проблемами з аудіо чи відеосигналом - наша команда досвідчених фахівців з радістю вам допоможе."} imageArr={differenceTv} serviceDescriptionTwo={"Ми розуміємо, як важливий для вас ваш монітор чи телевізор. Тому ми зосереджуємося на швидкому та надійному відновленні вашого пристрою. Наші професіонали володіють унікальним досвідом у ремонті техніки різних виробників та моделей, що дозволяє нам ефективно долати різноманітні проблеми, від заміни плат та компонентів до налаштування якості відтворення."} defectsArr={defectTvArr} priceArr={screenPrice} />} />
          </Route>
        </Routes>
      </Router>

    </div>
  );
}

export default App;
