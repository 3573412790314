import { useSelector } from "react-redux";
import "./ServicePrices.css";

function ServicePrices({ priceArr, isIphone, isMac, MacArr, iphoneArr }) {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <section className={`service__prices ${isDarkTheme ? "" : "light"}`}>
      <h2 className="service__prices-title body-m">
        Ціни на <span className="red-color">послуги</span>
      </h2>
      <div className="service__prices-wrapper">
        <div className="service__prices-texts">
          <p className="service__prices-text body-m">
            Загальні послуги (без урахування вартості запчастин):
          </p>
          <p className="service__prices-text body-m">Вартість</p>
        </div>
        <div className="service__prices-prices">
          {priceArr.map((price, idx) => {
            return (
              <div key={idx} className={`service__prices-price ${isDarkTheme ? "" : "light"}`}>
                <p className="service__prices-price-title light-text">
                  {price.title}
                </p>
                <p className="service__prices-price-price light-text">
                  {price.price}
                </p>
              </div>
            );
          })}
        </div>
        {isIphone ? (
          <>
            <div className="service__prices-texts">
              <p className="service__prices-text body-m">
              Ціни на ремонт iPhone (без урахування вартості запчастин):
              </p>
              <p className="service__prices-text body-m">Вартість</p>
            </div>
            {iphoneArr.map((price, idx) => {
              return (
                <div key={idx} className={`service__prices-price ${isDarkTheme ? "" : "light"}`}>
                  <p className="service__prices-price-title light-text">
                    {price.title}
                  </p>
                  <p className="service__prices-price-price light-text">
                    {price.price}
                  </p>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
        {isMac ? (
          <>
            <div className="service__prices-texts">
              <p className="service__prices-text body-m">
              Ціни на ремонт macBook (без урахування вартості запчастин):
              </p>
              <p className="service__prices-text body-m">Вартість</p>
            </div>
            {MacArr.map((price, idx) => {
              return (
                <div key={idx} className={`service__prices-price ${isDarkTheme ? "" : "light"}`}>
                  <p className="service__prices-price-title light-text">
                    {price.title}
                  </p>
                  <p className="service__prices-price-price light-text">
                    {price.price}
                  </p>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
      </div>
    </section>
  );
}

export default ServicePrices;
