import './Benefite.css'

function Benefite({src, title, description, isDarkTheme}) {
    return (
        <div className={`benefite ${isDarkTheme ? "" : "light"}`}>
            <img src={src} alt={description} className="benefite__icon" />
            <div className="benefite__texts">
                <h3 className={`benefite__title ${isDarkTheme ? "" : "light"} body-m`}>{title}</h3>
                <p className={`benefite__description ${isDarkTheme ? "" : "light"} light-text`}>{description}</p>
            </div>
        </div>
    )
}

export default Benefite