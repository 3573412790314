import './MainPluses.css'
import plusesIconOne from '../../../assets/icons/pluses-icon-one.png'
import plusesIconTwo from '../../../assets/icons/pluses-icon-two.png'
import plusesIconThree from '../../../assets/icons/pluses-icon-three.png'
import { useSelector } from 'react-redux';

function MainPluses() {
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

    return (
        <section className={`main__pluses ${isDarkTheme ? "" : "light"}`}>
            <div className="main__pluses-wrapper">
            <div className="main__plus">
                <img src={plusesIconOne} alt="іконка наших переваг" className="main__plus-icon" />
                <h3 className="main__plus-title body-m">Доступні ціни</h3>
                <p className="main__plus-description light-text">Конкурентні ціни, які не впливають на якість.</p>
            </div>
            <div className="main__plus">
                <img src={plusesIconTwo} alt="іконка наших переваг" className="main__plus-icon" />
                <h3 className="main__plus-title body-m">Досвід та компетентність</h3>
                <p className="main__plus-description light-text">Багаторічний досвід, робота з різними поломками.</p>
            </div>
            <div className="main__plus">
                <img src={plusesIconThree} alt="іконка наших переваг" className="main__plus-icon" />
                <h3 className="main__plus-title body-m">Широкий спектр послуг</h3>
                <p className="main__plus-description light-text">Від програмних до фізичних поломок - комплексний підхід.</p>
            </div>
            </div>
        </section>
    )
}

export default MainPluses