function LunaIcon({ isDark }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.21998 0.00860596C3.03963 1.28998 0 5.18021 0 9.78038C0 15.4241 4.57515 19.9993 10.2189 19.9993C14.768 19.9993 18.6228 17.0267 19.947 12.9181C16.2003 14.1349 11.8339 13.5198 9.06437 10.7502C6.26098 7.94685 5.83933 3.68177 7.21998 0.00860596Z"
        fill={`${isDark ? "black" : "white"}`}
      />
    </svg>
  );
}

export default LunaIcon;
