import AboutTeam from "./AboutTeam/AboutTeam";
import "./AboutUs.css";
import AboutUsValues from "./AboutUsValues/AboutUsValues";

function AboutUs() {

  return (
    <div className="about__us-page">
      <h2 className="about__us-title body-m">
        Про <span className="red-color">нас</span>
      </h2>
      <div className="about__us-description-wrapper">
        <p className="about__us-description light-text">
          Ми - команда висококваліфікованих фахівців, які з великою пристрастю
          та професіоналізмом займаються ремонтом електроніки. Наша місія
          полягає в тому, щоб забезпечити швидкий та якісний сервіс, відновити
          ваші улюблені гаджети та повернути їх до життя.
        </p>
        <p className="about__us-description light-text">
        Ми не просто відновлюємо техніку, ми створюємо спільноту, де ви можете знайти необхідні поради та інформацію від наших експертів. Ваше задоволення - наша головна мета, тому ми завжди готові зрозуміти ваші потреби та думки.
        </p>
        <p className="about__us-description light-text">
          Ми розуміємо, наскільки важлива ваша техніка в сучасному житті. Тому
          ми надаємо зручні та швидкі рішення - від діагностики до відновлення
          працездатності. Ваша довіра - наша найбільша нагорода, і ми горді бути
          вашими партнерами у забезпеченні найкращої якості ремонту та
          обслуговування техніки.
        </p>
      </div>
      <AboutUsValues />
      <AboutTeam />
    </div>
  );
}

export default AboutUs;
