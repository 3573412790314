import "./Header.css";
import logo from "../../assets/images/logo.webp";
import logoLight from "../../assets/images/logo-light.png";
import { Link } from "react-router-dom";
import {
  PRICES_ROUTE,
  CONTACTS_ROUTE,
  ABOUTE_ROUTE,
  PHONE_TABLETS_ROUTE,
  LAPTOPS_ROUTE,
  COMPUTER_ROUTE,
  MONITORES_TV_ROUTE,
} from "../../utils/paths";
import { useState } from "react";
import Triangle from "./Triangle/Triangle";
import { useDispatch, useSelector } from "react-redux";
import { setClearIsFormSended, setIsActive, setIsClose } from "../../redux/ModalSlice/ModalSlice";
import ToggleThemeButton from "../ToggleThemeButton/ToggleThemeButton";
import { toggleTheme } from "../../redux/ToggleThemeSlice/ToggleThemeSlice";
import { useEffect } from "react";
import Hamburger from "hamburger-react";
import {
  setBurgerIsActive,
  setBurgerIsClose,
} from "../../redux/BurgerSlice/BurgerSlice";

function Header() {
  const dispatch = useDispatch();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const [isChecked, setIsChecked] = useState(isDarkTheme);
  const isActive = useSelector((state) => state.burger.isBurgerActive);
  const [isOpen, setOpen] = useState(isActive);

  const handleMenuOpen = () => {
    setIsMenuActive(true);
  };

  const handleMenuClose = () => {
    setIsMenuActive(false);
  };

  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setBurgerIsClose());
    setOpen(false);
  };

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
    console.log("duck");
    setIsChecked((prev) => !prev);
  };

  useEffect(() => {
    setIsChecked(isDarkTheme);
    setOpen(isActive);
  }, [isDarkTheme, isActive]);

  return (
    <header className={`header ${isDarkTheme ? "" : "light"}`}>
      <div className="header__wrapper">
        <div className="header__logo-navigation">
          <Link to={"/"}>
            <img
              src={isDarkTheme ? logo : logoLight}
              alt="наш логотип"
              className="header__logo"
            />
          </Link>
          <nav className="header__nav">
            <div onMouseOver={handleMenuOpen} className="header__link-first">
              <div
                onMouseLeave={handleMenuClose}
                className={`header__menu ${isDarkTheme ? "" : "light"} ${
                  isMenuActive ? "active" : ""
                }`}
              >
                <Link
                  onClick={handleMenuClose}
                  to={PHONE_TABLETS_ROUTE}
                  className={`header__menu-link ${
                    isDarkTheme ? "" : "light"
                  } body-m`}
                >
                  Телефони/Планшети
                </Link>
                <Link
                  onClick={handleMenuClose}
                  to={LAPTOPS_ROUTE}
                  className={`header__menu-link ${
                    isDarkTheme ? "" : "light"
                  } body-m`}
                >
                  Ноутбуки
                </Link>
                <Link
                  onClick={handleMenuClose}
                  to={COMPUTER_ROUTE}
                  className={`header__menu-link ${
                    isDarkTheme ? "" : "light"
                  } body-m`}
                >
                  Комп'ютери
                </Link>
                <Link
                  onClick={handleMenuClose}
                  to={MONITORES_TV_ROUTE}
                  className={`header__menu-link ${
                    isDarkTheme ? "" : "light"
                  } body-m`}
                >
                  Монітори/Телевізори
                </Link>
              </div>
              <p
                className={`header__link ${isDarkTheme ? "" : "light"} body-r`}
              >
                Ремонт
              </p>
              <div className={`triangle ${isMenuActive ? "active" : ""} `}>
                {" "}
                <Triangle isActive={isMenuActive} />
              </div>
            </div>
            <Link
              to={ABOUTE_ROUTE}
              className={`header__link ${isDarkTheme ? "" : "light"} body-r`}
            >
              Про нас
            </Link>
            <Link
              to={PRICES_ROUTE}
              className={`header__link ${isDarkTheme ? "" : "light"} body-r`}
            >
              Ціни
            </Link>
            <Link
              to={CONTACTS_ROUTE}
              className={`header__link ${isDarkTheme ? "" : "light"} body-r`}
            >
              Контакти
            </Link>
          </nav>
        </div>
        <div className="header__buttons">
          <div
            onClick={handleToggleTheme}
            className="toggle__header-button-wrapper"
          >
            <ToggleThemeButton isChecked={!isChecked} />
          </div>
          <div className="burger__button">
          <Hamburger
            size={28}
            toggled={isOpen}
            onToggle={(toggled) => {
              if (toggled) {
                dispatch(setIsClose());
                dispatch(setClearIsFormSended())
                dispatch(setBurgerIsActive());
                setOpen(true);
              } else {
                dispatch(setBurgerIsClose());
                setOpen(false);
              }
            }}
          />{" "}
          </div>
          <button
            onClick={handleModalOpen}
            className={`header__button ${isDarkTheme ? "" : "light"} body-r`}
          >
            Зв'язатися з нами
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
