import { useSelector } from "react-redux";

function Triangle({isActive}) {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
    >
      <path d="M5 8L0.669873 0.499998L9.33013 0.5L5 8Z" fill={`${isActive ? "red" : isDarkTheme? "white" : "black"}`} />
    </svg>
  );
}

export default Triangle;
